import { FaqItem } from '@type-declarations/faq';
import { FAQPage, WithContext } from 'schema-dts';

export default function FaqSchema({ items }: { items: FaqItem[] }) {
  const schema: WithContext<FAQPage> = {
    '@type': 'FAQPage',
    '@context': 'https://schema.org',
    mainEntity: items.map(item => ({
      '@type': 'Question',
      name: item.title,
      acceptedAnswer: {
        '@type': 'Answer',
        text: item.text,
      },
    })),
  };

  return (
    <script
      type="application/ld+json"
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }}
    />
  );
}
